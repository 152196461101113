/* Tabs */

.tabs {
  border-radius: 50px;
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.tab {
  z-index: 1;
}

.scroller {
  overflow: visible !important;
}

.selected {
  font-weight: bold;
}

.indicator {
  height: 100%;

  border-radius: 50px;

  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
}

/* Chart */

.title {
  position: relative;
  top: 1.25rem;
  left: 1rem;

  margin: 0;

  font-size: 14px;
  font-weight: 600;
}

.legend {
  transform: translateY(-1rem);
}

.legendMark {
  width: 14px;
  height: 9px;
  ry: 5;
}

/* SMS/Voice */

.card {
  width: 100%;
  margin-top: 1rem;

  border-radius: 10px;

  background-color: #f7f7f7;
  box-shadow: none;
}

/* Data */

.pot {
  width: 100%;
  border-radius: 10px;
}
