#address .react-tabs {
    -webkit-tap-highlight-color: transparent;
}

#address .react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 0px;
    padding: 10px 0px 0px 0px;
    text-align: center;
}

#address .react-tabs__tab {
    display: inline-block;
    border: 1px solid #aaa;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 11px 10px 0px 0px;
    box-shadow: 0px -3px 6px #00000029;
    margin: 0px 10px;
}

#address .react-tabs__tab--selected {
    background: #E3264E;
    border-radius: 11px 10px 0px 0px;
    color: white;
    padding-top: 10px;
}

#address .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
}

#address .react-tabs__tab:focus {
    border-color: hsl(208, 99%, 50%);
    outline: none;
}

#address .react-tabs__tab:focus:after {
    content: "";
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
}

#address .react-tabs__tab-panel {
    display: none;
}

#address .react-tabs__tab-panel--selected {
    display: block;
}
