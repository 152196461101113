button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  height: fit-content;
}

p {
  margin: 0;
}

.panel {
  background: white;
  z-index: 10000;
  max-width: 400px;
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
  overflow: clip;

  hr {
    margin: 0;
  }
}

.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    padding: 0.5rem;
    font-weight: bold;
  }
}

.contentContainer {
  padding: 0.5rem;
  white-space: pre-line;
  overflow: scroll;
}
