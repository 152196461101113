#root {
    height: 100vh;
}

.swal2-container {
    z-index: 2000 !important;
}

.hide {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
}

.downarrow {
    transition: all 0.5s;
}

.downarrow.active {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
}

iframe {
    border: none;
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    margin-top: 30px;
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}

    /* Style your items */
    .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
        margin-bottom: 30px;
    }

::-webkit-scrollbar {
    background: transparent; /*#1e1e2f*/
    background-color: transparent;
    width: 4px;
    height: 2px;
}

::-webkit-scrollbar-thumb {
    z-index: 999;
    background-color: hsl(208,39%,25%); /*hsl(208,39%,25%);*/
    border-radius: 50px;
}

::-webkit-scrollbar-button {
    display: none;
}

::-webkit-scrollbar-track {
    background: transparent;
    background-color: transparent; /*rgb(245,245,245);*/
}

::-webkit-scrollbar-track-piece {
    background: transparent;
    background-color: transparent;
}

input:-webkit-autofill::first-line {
    font-family: 'itc-avant-garde-gothic-pro', 'sans-serif';
    font-size: 16px;
}

#invoicePdf {
    margin-top: 65px;
}

.slick-list {
    margin: 0 -10px;
}

.slick-slide > div {
    padding: 0 10px;
}

.slick-prev:before,
.slick-next:before {
    color: #e91e63 !important;
}

.load {
    position: relative;
    margin: 50px auto;
    width: 100px;
    height: 80px;
}

.gear {
    position: absolute;
    z-index: -10;
    width: 40px;
    height: 40px;
    animation: spin 5s infinite;
}

.two {
    left: 40px;
    width: 80px;
    height: 80px;
    animation: spin-reverse 5s infinite;
}

.three {
    top: 45px;
    left: -10px;
    width: 60px;
    height: 60px;
}

@keyframes spin {
    50% {
        transform: rotate(360deg);
    }
}

@keyframes spin-reverse {
    50% {
        transform: rotate(-360deg);
    }
}

.lil-circle {
    position: absolute;
    border-radius: 50%;
    box-shadow: inset 0 0 10px 2px gray, 0 0 50px white;
    width: 100px;
    height: 100px;
    opacity: .65;
}

.blur-circle {
    position: absolute;
    top: -19px;
    left: -19px;
}

.text {
    color: gray;
    font-size: 18px;
    text-align: center;
    width: 100%;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
/*
    Spinner
*/
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #9370DB;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

    #loader:before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #BA55D3;
        -webkit-animation: spin 3s linear infinite;
        animation: spin 3s linear infinite;
    }

    #loader:after {
        content: "";
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: #FF00FF;
        -webkit-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
    }

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
/**
* ----------------------------------------------
* Accordion
* ----------------------------------------------
**/
.u-position-absolute {
    position: absolute;
}

.u-position-relative {
    position: relative;
}

.accordion {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__item--has-icon {
    position: relative;
}

.accordion__title {
    background-color: transparent;
    color: #444;
    cursor: pointer;
    padding: 10px;
    width: 100%;
    text-align: left;
    border: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

    .accordion__title[aria-selected="true"] {
        background-color: #E6E6E6;
    }

.accordion__body {
    background-color: white;
    padding: 20px;
    display: block;
    animation: fadein 0.35s ease-in;
}

.accordion__body--hidden {
    display: none;
    opacity: 0;
    animation: fadein 0.35s ease-in;
}

.accordion__title > *:last-child,
.accordion__body > *:last-child {
    margin-bottom: 0;
}

.accordion__arrow {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 12px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -6px;
}

    .accordion__arrow::after,
    .accordion__arrow::before {
        display: block;
        position: absolute;
        top: 50%;
        width: 10px;
        height: 2px;
        background-color: currentColor;
        content: '';
    }

    .accordion__arrow::before {
        left: 4px;
        transform: rotate(45deg);
    }

[aria-expanded='true'] .accordion__arrow::before, [aria-selected='true'] .accordion__arrow::before {
    transform: rotate(-45deg);
}

.accordion__arrow::after {
    right: 4px;
    transform: rotate(-45deg);
}

[aria-expanded='true'] .accordion__arrow::after, [aria-selected='true'] .accordion__arrow::after {
    transform: rotate(45deg);
}

.accordion__arrow::before,
.accordion__arrow::after {
    transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes move-down {
    0% {
        transform: translateY(0);
    }

    10% {
        transform: translateY(0);
    }

    20% {
        transform: translateY(5px);
    }

    30% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes move-up {
    0% {
        transform: translateY(0);
    }

    10% {
        transform: translateY(0);
    }

    20% {
        transform: translateY(-5px);
    }

    30% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(0);
    }
}

.accordion__title--animated:hover .accordion__arrow {
    animation-name: move-down;
    animation-duration: 1.5s;
}

.accordion__title--animated[aria-expanded='true']:hover .accordion__arrow {
    animation-name: move-up;
    animation-duration: 1.5s;
}

.rc-collapse {
    background-color: transparent;
    border-radius: 3px;
    border: 0px solid #d9d9d9;
}

.rc-collapse-anim-active {
    transition: height 0.2s ease-out;
}

.rc-collapse > .rc-collapse-item {
    border-top: 0px solid #d9d9d9;
}

    .rc-collapse > .rc-collapse-item:first-child {
        border-top: none;
    }

    .rc-collapse > .rc-collapse-item > .rc-collapse-header {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        line-height: 16px;
        padding: 10px 0px;
        color: #FFF;
        cursor: pointer;
        outline: none;
    }

        .rc-collapse > .rc-collapse-item > .rc-collapse-header .arrow {
            display: inline-block;
            content: '\20';
            width: 0;
            height: 0;
            font-size: 0;
            line-height: 0;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            border-left: 12px solid #FFF;
            vertical-align: middle;
            margin-right: 8px;
        }

        .rc-collapse > .rc-collapse-item > .rc-collapse-header .rc-collapse-extra {
            margin: 0 16px 0 auto;
        }

.rc-collapse > .rc-collapse-item-disabled > .rc-collapse-header {
    cursor: not-allowed;
    color: #999;
    background-color: transparent;
}

.rc-collapse-content {
    overflow: hidden;
    color: #FFF;
    padding: 0px;
    line-height: 18px;
    background-color: transparent;
}

    .rc-collapse-content > .rc-collapse-content-box {
        margin-top: 6px;
        margin-bottom: 6px;
    }

.rc-collapse-content-inactive {
    display: none;
}

.rc-collapse-item:last-child > .rc-collapse-content {
    border-radius: 0 0 3px 3px;
}

.rc-collapse > .rc-collapse-item-active > .rc-collapse-header .arrow {
    position: relative;
    top: 2px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 12px solid #FFF;
    margin-right: 6px;
}

.rubber {
    box-shadow: 0 0 0 3px blue, 0 0 0 2px blue inset;
    border: 2px solid transparent;
    border-radius: 4px;
    display: inline-block;
    padding: 5px 2px;
    line-height: 22px;
    color: blue;
    font-size: 24px;
    font-family: 'Black Ops One', cursive;
    text-transform: uppercase;
    text-align: center;
    opacity: 0.4;
    width: 300px;
    transform: rotate(-10deg);
}

.white-links a, .white-links a:hover, .white-links a:focus {
    color: #FFF !important;
    font-weight: 500;
}
